#message-clients-container {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;

  .title-message {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    i {
      color: #5b2e90;
      margin-right: 0.5rem;
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      margin: 0;
    }
  }

  .message-text {
    font-size: 12px;

    p {
      margin-bottom: 0.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-message-clients-container {
  .title-message {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    i {
      color: #5b2e90;
      margin-right: 0.5rem;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }
  }

  .message-text {
    font-size: 1rem;

    p {
      margin-bottom: 0.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .message-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      width: 80px;
    }
  }
}
