#config-pbm-card{
    .card-body
    {
        flex-direction: column;
        overflow: hidden;
    }
    .card-body .body
    {
        flex: 1 1 auto;
    }

    div.div-master {
        display: flex;
    }
   
    

    div.active{
        box-shadow: 0 0 1px 0px #C2BEBE;
        flex: 1 1 auto;
    }

    div.tab-content{        
        flex: 1 1 auto;            
    }

    .config-fp{        
        flex: 1 1 auto;
    }

    .nav-tabs .nav-link.active{
        font: normal normal bold 16px/20px Dosis;
        color: #5B2E90;
        background: white 0% 0% no-repeat padding-box;        
        border-bottom: 3px solid #5B2E90;
    }

    .nav-tabs .nav-link{
        font: normal normal bold 16px/20px Dosis;
        background: #F1F3F6 0% 0% no-repeat padding-box;
        margin-right: 10px;
        padding: 15px;        
        color: #C2BEBE;           
        box-shadow: 0px 0px 6px #00000029;        
    }
    .nav-tabs .nav-item {
        margin-bottom: 0px;
    }
    .rbContainer{        
        margin-bottom: unset;
        margin-top: 15px;
        margin-left: 3px;
    }
    .form-row{
        margin: 0 15px;
    }
}

