#modalAuthorization {
  z-index: 10000;

  .modalAuthorization {
    box-shadow: 3px 5px 15px #5b2e90;
  }

  .operator-input {
    border: 2px solid #6e6d6d;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .operator-input:focus {
    box-shadow: 0px 0px 10px #5b2e90;
    outline-color: #5b2e90;
    outline: 0px;
  }

  .password-input {
    margin-bottom: 15px;
  }

  .btn-purple {
    width: 100%;
  }

  .spanAuthorizationMessage {
    color: red;
  }

  .divDescricaoSupervisor {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    border-bottom: 1px solid #b9b7b7;
  }
}