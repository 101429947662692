
.deliver-wrapper {
    display: flex,    
}

.deliver-wrapper .button {
    background-color: #fff;     
    border: none;  
    position: relative;
    display: inline-block;
    border-bottom: 0px dotted black;
    
    &:focus {
        box-shadow: none;
    }
    &:active {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

.deliver-wrapper .button:hover .tooltiptext {
    visibility: visible;
    opacity: 1;    
}

.deliver-wrapper .button .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #5b2e90;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;   
    position: absolute;
    left: 50%;
    margin-left: -60px;        
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    top: 100%;
} 
