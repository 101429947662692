#card-pbm-search-authorization {
    .card-body
    {
        overflow: hidden;
    }
    h3 {
        font-size: 20px;
    }
    i {
        vertical-align: middle;
    }
    .form-group.col:nth-child(3), .form-group.col:last-child {
        flex: 0 0 180px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .accessAuthorizer{
        display: flex;
        align-items: center;
        color: #1a73e8;
        cursor: pointer;
    }

    .right{
        display: flex;
        justify-content: flex-end;
        padding-right: 5px;
    }
}
