#trnCentreResume {
    .table {
        display: table;
        width: 100%;
        border-collapse: collapse;
    }

    .div-empty-message {
        font-size: 30px;
        font-style: italic;
        text-align: center;
        color: #b2b5b7;
    }

    .div-message {
        font-size: 25px;
        // font-style: italic;
        text-align: center;
        color: #b2b5b7;
        margin-bottom: 10px;
    }

    .row-alert {
        background-color: #f3e476;
    }

    .sub-table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        flex: auto;
    }

    .sub-row {
        display: flex;
        margin-left: 30%;
        //font-style: italic;
    }

    .sub-cell {
        display: flex;
        padding: 2px;
        width: 14%;
        flex: auto;
        /* adjust the width as per your requirement */
    }

    .row {
        display: flex;
        border-top: 1px solid rgb(223, 215, 212);

    }

    .row-infos:hover {
        background-color: #ffb200;
        //box-shadow: 0 0 8px #5b2e90;
        cursor: pointer;
    }

    .sub-row-infos:hover {
        background-color: #ffb200;
        //box-shadow: 0 0 8px #5b2e90;
        cursor: pointer;
    }

    .cell {
        display: table-cell;
        padding: 5px;
        width: 10%;
        /* adjust the width as per your requirement */
    }

    .cell-button {
        display: inline-flex;
        padding-top: unset;
    }

    .header-cell {
        background-color: #e7e4e4;
        font-weight: bold;
        flex: auto;
    }

    /* Responsive styles */
    @media (max-width: 600px) {
        .cell {
            display: block;
            width: 100%;
        }
    }

    .cell-status {
        width: 8%;
    }

    .cell-hour {
        width: 15%;
    }

    .cell-name {
        width: 37%;
    }

    .cell-cardNumber {
        width: 10%;
    }

    .cell-nsu {
        width: 12%;
    }

    .cell-detail {
        width: 8%;
    }

    .material-icons {
        color: #5b2e90;
        margin-left: 10px
    }

    .material-icons:hover {
        cursor: pointer;
    }

    .cell-action {
        width: 10%;
    }

    .more-options {
        align-self: center;
        padding: unset;

        i {
            cursor: pointer;
        }
    }

    .dropdown-content {
        position: absolute;
        background-color: #f1f1f1;
        min-width: 100px;
        margin-left: -30px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            cursor: pointer;

            &:hover {
                background-color: #c4a2d5;
            }
        }
    }

    .cell-barcode {
        width: 20%;
    }

    .cell-description {
        width: 40%;
    }

    .cell-requestedQuantity {
        width: 15%;
    }

    .cell-authorizedQuantity {
        width: 10%;
    }

    .cell-discountValue {
        width: 20%;
    }

    .header-cell-barcode {
        width: 20%;
    }

    .header-cell-description {
        width: 35%;
    }

    .header-cell-requestedQuantity {
        width: 12%;
    }

    .header-cell-authorizedQuantity {
        width: 13%;
    }

    .header-cell-discountValue {
        width: 20%;
    }
}