#pbm-config-card{
    .card-body
    {
        flex-direction: column;
        overflow: hidden;
    }
    .card-body .body
    {
        flex: 1 1 auto;
    }
    .card-body .footer
    {
        flex: 0 0 60px;
    }    
}