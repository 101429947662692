.rbContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
    margin-bottom: 1em;
}
.rbContainer div {
    flex: 0 0 100%;
}
.rbContainer button {
    border: none;
    padding: unset;
    text-align: left;
    flex: 1 1 auto;
    background-color: transparent;
}
.rbContainer button:focus {
    box-shadow: 0 0 5px 0.05rem rgba(91, 46, 144, 1);
    border-color: #ced4da;
    outline: none;
}
.rbContainer button:focus i,
.rbContainer button:active i,
.rbContainer button:hover i,
.rbContainer button i {
    color: #5b2e90;
    font-size: 24px;
    vertical-align: text-bottom;
    margin-right: 5px;
}
.rbContainer button span {
    display: inline-block;
    font-weight: normal;
}
