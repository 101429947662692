#config-terminal-card {
    height: 100%;

    div.div-master {
        display: flex;
    }

    .card-body {
        overflow: auto;
        overflow-x: hidden;
    }

    .div-container {
        margin: 10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7px;
        padding: 30px 50px;
    }

    label.label-title {
        font: normal normal bold 16px/20px Dosis;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 100%;
    }

    label.subtitle-left {
        text-align: left;
        margin-top: 30px;
    }

    label.label-text {
        font: normal normal 16px/20px Dosis;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 5px;
    }

    .SlideToggle {
        align-Self: center;
        margin-Left: 5px;
        margin-Top: 0px;
    }

    .rbContainer {
        padding: unset;
        margin-bottom: unset;
        margin-top: 15px;
        margin-left: 0px;
    }

    .radioPrinter .rbContainer {
        flex-direction: column;
    }

    .radioPrinter {
        flex-direction: column;
    }

    .radioPrinter .rbContainer button {
        margin-left: 0px;
        text-transform: inherit;
    }

    #posId {
        width: 80%;
        display: inline;
    }

    .footer {
        margin: 14px 0;
    }


    div.active {
        box-shadow: 0 0 1px 0px #C2BEBE;
        flex: 1 1 auto;
    }

    div.tab-content {
        flex: 1 1 auto;
    }

    .nav-tabs .nav-link.active {
        font: normal normal bold 16px/20px Dosis;
        color: #5B2E90;
        background: white 0% 0% no-repeat padding-box;
        border-bottom: 3px solid #5B2E90;
    }

    .nav-tabs .nav-link {
        font: normal normal bold 16px/20px Dosis;
        background: #F1F3F6 0% 0% no-repeat padding-box;
        margin-right: 10px;
        padding: 15px;
        color: #C2BEBE;
        box-shadow: 0px 0px 6px #00000029;
    }

    .nav-tabs .nav-item {
        margin-bottom: 0px;
    }

    .rbContainer {
        margin-bottom: unset;
        margin-top: 15px;
        margin-left: 3px;
    }

    .form-row {
        margin: 0 15px;
    }

    .card-body {
        flex-direction: column;
        overflow: hidden;
    }

    .card-body .body {
        flex: 1 1 auto;
    }

    div.div-master {
        display: flex;
    }

    #tabs-tabpane-variaveis {
        padding-bottom: 20px;
    }

    #tabs-tabpane-impressao {
        padding-bottom: 20px;
    }

    #tabs-tab-emissaoDocumentos {
        padding-bottom: 20px;
    }
}