#config-epharma-card{    
    label.label-title {
        font: normal normal bold 16px/20px Dosis;
        text-align: center;    
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 8px;
    }
    div.body{
        flex: 1 1 auto;
    }
}