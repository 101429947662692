#pbm-init-orizon-card {
    .rbContainer {
        margin-bottom: 0px;
        flex-wrap: nowrap;
    }
    textarea.form-control {
        line-height: normal;
        height: auto;
    }
    .card-body {
        flex-direction: column;
        overflow-x: hidden;
    }
    .card-body .body {
        flex: 1 1 auto;
    }
    .card-body .footer {
        flex: 0 0 60px;
        text-align: right;
    }
    .group {
        display: flex;
        flex-direction: column;
        position: relative;

        .plans {
            select {
                margin-bottom: 5px;
            }

            .updatePlans {
                display: flex;
                align-items: center;
                color: #1a73e8;
                cursor: pointer;
            }
        }

    }

    .group .tblHeader,
    .group .tblRow {
        display: flex;
        flex-wrap: nowrap;
        margin: 0px;
        padding: 0px;
        list-style: none;
        align-items: center;
    }
    .group .tblHeader li {
        font-weight: bold;
        flex: 1 1 20%;
        text-align: left;
        padding: 5px;
    }
    .group .tblRow li {
        flex: 1 1 20%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding: 5px;
    }
    .group .tblRow li:first-child,
    .group .tblHeader li:first-child {
        flex: 0 0 40%;
        text-align: left;
    }
    .group .tblRow li:last-child,
    .group .tblHeader li:last-child {
        flex: 0 0 160px;
    }

    .group .tblRow li:nth-child(2),
    .group .tblHeader li:nth-child(2) {
        text-align: right;
    }
    .group .tblRow li:nth-child(3),
    .group .tblHeader li:nth-child(3) {
        text-align: center;
    }
    .group .tblRow:nth-child(2n + 1) {
        background-color: #f3f3f3;
    }
    .group .tblRow:last-child {
        margin-bottom: 15px;
    }
    .group > span {
        display: block;
        margin-bottom: 10px;
    }
    .inlineGroup {
        display: flex;
        flex-wrap: nowrap;
    }
    .inlineGroup .inlineSubGroup:first-child {
        flex: 0 0 40%;
    }
    .inlineGroup .inlineSubGroup {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .inlineGroup .inlineSubGroup > span {
        flex: 0 0 100%;
        display: block;
        position: relative;
        margin-bottom: 10px;
    }
    .inlineGroup .inlineSubGroup > span::after,
    .group > span::after {
        display: block;
        content: " ";
        position: absolute;
        top: 11px;
        right: 5px;
        background-color: #ccc;
        height: 1px;
    }
    .group > span::after {
        left: 140px;
    }
    .inlineGroup .inlineSubGroup > span.benef::after {
        left: 135px;
    }
    .inlineGroup .inlineSubGroup > span.receita::after {
        left: 105px;
    }
    .inlineGroup .inlineSubGroup > * {
        flex: 1 1 auto;
        margin-right: 10px;
    }
    .group .usercard {
        display: flex;
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        flex-direction: column;
        height: 205px;
    }
    .group .usercard > * {
        text-align: center;
        margin: 3px 0px;
    }
    .group .usercard span {
        flex: 1 1 auto;
    }
    .group .usercard div {
        flex: 0 0 180px;
    }
    .group .usercard img {
        height: 150px;
        cursor: pointer;
    }
    .footer {
        display: flex;
        justify-content: flex-end;
    }
}
