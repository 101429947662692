#pbm-config-card {
    .card-body {
        flex-direction: column;
        overflow: hidden;
    }

    .card-body .body {
        flex: 1 1 auto;
        padding-top: 30px;
    }

    .card-body .footer {
        flex: 0 0 60px;
    }

    .body {
        padding-top: 30px;
    }
}

#bodytrncentre {
    padding-top: 30px;
}

#loadtables {
    align-self: center;
    height: 35px;
    margin-top: 26px;
}