.info-conecta-transaction {
  min-height: min-content;
  padding: 2px 25px;
  font-size: 16px;
  .sub-title {
    font-weight: bold;
    margin-right: 5px;
  }
}


.item-name-tooltip {  
  margin-left: 150px;
  display: block;
  opacity: 1;
  position: absolute;
  z-index: 999;
  left: 10px;
  background-color: #fff;
  border: 1px solid #5b2e90;
  border-radius: 4px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.2); 
  min-height: 30px;  
  padding: 3px 4px;
  transition: all ease-in-out 200ms;
  transform: translateY(-85%);
}


.table-title {
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  display: block;
  color: #6E35B1;
  text-align: left;
  
  margin-left: 3px;
}

.mdl-footer {
   background-color: #F1F3F6;
   padding: 20px;
}

.mdl-footer td{
  color: #6E35B1;  
  flex: 0 0 auto;  
}

.mdl-footer label{  
  flex: auto;
  margin-left: 10px;
}

.table-title.not-approved span {
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  display: block;
  color: #6E35B1;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 11px;
}

.mdl-body-confirmation p {
  font-size: 17px;
}

.table {
  margin-top: 8px;
}

.table-sm {
  table-layout: fixed;
}

.table th {
  padding: 1;
  height: 48px;
  vertical-align: middle;
}

.tdDesc {     
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;     
}

.table td {
  white-space: nowrap;
  vertical-align: middle;
}

tbody tr:hover {
  background-color: #ffb300c5;
}

tbody td.details-conecta {  
  align-items: center;
  vertical-align: middle;
  a{
    vertical-align: super;
  }

  i {
    color: #007BFF;
    margin-right: 6px;
  }
}

.inputQtt{
  width: 45px;
}

.labelDesc0 {
  padding: 4px 12px;
  background-color: #6FCF97;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  margin: 0 0 0;
}

.labelDesc1 {
  padding: 4px 12px;
  background-color: gray;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  vertical-align: middle;
  margin: 0 0 0;
}

.labelDesc2 {
  padding: 4px 12px;
  background-color: red;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  vertical-align: middle;
  margin: 0 0 0;
}

.labelDesc3 {
  padding: 4px 12px;
  background-color: #6E35B1;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  vertical-align: middle;
  margin: 0 0 0;
}


.info-icon {
  color: #5b2e90;
  font-size: 23px;
  &:hover {
    cursor: pointer;
  }
}