#modalValidation {
  .modalValidation {
    background-color: rgba(10, 23, 55, 0);
  }

  .divCard {
    align-items: center;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    width: 700px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    //font-family: 'Ubuntu', 'Dosis' !important;
    font-size: 17px;
    //transition: margin 1s;
  }

  .divHasNoProductInfo {
    display: flex;

    .btn-default {
      margin-left: 100px;
    }
  }

  .divFormOfPayment:hover {
    box-shadow: 2px 1px 5px #5b2e90;
    //border: 1px solid #5b2e90;
  }

  .divProductDescription {
    margin-bottom: 10px;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;

    .cProd {
      width: 340px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .cQtde {
      display: flex;
      margin-left: 35px;
      text-align: center;
      width: 60px;
    }
  }

  .divInfos {
    display: flex;
  }

  .divSellerInfo {
    display: flex;

    .btn-purple {
      width: 202px;
      margin-left: 15px;
    }
  }

  .divCustomerInfo {
    display: flex;

    .btn-purple {
      margin-left: 70px;
    }
  }

  .divInfosCol {
    display: block;
  }

  .divProductColuns {
    display: flex;

    .cProd {
      width: 340px;
    }
  }

  .divFormOfPayment {
    width: 270px;
    height: 39px;
    font-size: 16px;
    color: #cccbca;
    border: 1px solid #c7c6c9;
    border-radius: 5px;

    .description {
      margin-top: 10px;
      margin-left: 10px;
    }

    .material-icons {
      position: absolute;
      margin-top: -30px;
      margin-left: 245px;
    }
  }

  .divCard:hover {
    //margin-top: 20px;
    //margin-bottom: 40px;
    padding: 17px;
    box-shadow: 3px 1px 10px #5b2e90;
    border: 3px solid #5b2e90;
    cursor: pointer;
  }

  .material-icons {
    color: #5b2e90;
    position: absolute;
  }

  .divBorder {
    border: 2px solid #dedce0;
    border-radius: 7px;
    padding: 10px
  }

  .ModalBody {
    background-color: rgba(10, 23, 55, 0);
  }

  .divTitulo {
    font-weight: bold;
    margin-bottom: 10px;
    // border-bottom: 2px solid #acaaaa;
  }

  .divPlaceHolder {
    position: absolute;
    margin-top: 10px;
    margin-left: 30px;
  }

  .divDescription {
    margin-bottom: 10px;
    margin-left: 25px;
    margin-top: 3px;
  }

  .operator-input {
    border: 1px solid #d1d0d0;
    border-radius: 5px;
    height: 39px;
    font-size: 15px;
    margin-right: 20px;
    text-align: center;
    width: 400px
  }

  .operator-input:focus {
    box-shadow: 0px 0px 10px #5b2e90;
    outline-color: #5b2e90;
    outline: 0px;
  }

  .btn-purple {
    flex: auto;
  }

  .divSolution {
    display: flex;
  }

  .menu-area {
    cursor: pointer;
    position: absolute;
    width: 270px;
    display: grid;
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 15px;
    z-index: 500;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 auto;
      font-weight: bold;
      height: 40px;
      line-height: 42px;
      padding: 0 10px;
      //color: ${theme.palette.grayscale.ultraDark};

      &:focus,
      &:hover {
        background-color: #ffb200;
      }
    }
  }
}