#card-seven{ 

  .title{
    text-align: center;
  } 

  .btn{
    width: 200px;
    margin-top: 15px;
    align-self: flex-end;
  }

  .card-body div{
    flex: initial;
  }

  .headerTbSeven,
  .rowTbSeven {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;   
    align-items: center;
  }

  .headerTbSeven div,
  .rowTbSeven div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 25px;
  }

  .rowTbSeven div:nth-child(7) {
    flex: 0 0 50%;
    text-align: left;  
  }

  .headerTbSeven {
    font-weight: bold;  
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-top: 10px;
  }

  .rowTbSeven:nth-child(2n) {
    background-color: rgba(0,0,0,.05);
  }

  .rowTbSeven:hover {
    background-color: #ffb200;
    box-shadow: 0 0 8px #5b2e90;
    cursor: pointer;
  }

  .rowTbSeven{  
    padding-left: 10px;
    padding-bottom: 5px;
  }

  .headerTbSeven div:nth-child(1),
  .rowTbSeven div:nth-child(1) {
    flex: 1 0 15%;  
  }

  .headerTbSeven div:nth-child(2),
  .rowTbSeven div:nth-child(2) {
    flex: 1 0 40%;  
  }

  .headerTbSeven div:nth-child(3),
  .rowTbSeven div:nth-child(3) {
    flex: 1 0 10%;  
  }

  .headerTbSeven div:nth-child(4),
  .rowTbSeven div:nth-child(4) {
    flex: 1 0 10%;  
  }

  .headerTbSeven div:nth-child(5),
  .rowTbSeven div:nth-child(5) {
    flex: 1 0 10%;  
  }

  .headerTbSeven div:nth-child(6),
  .rowTbSeven div:nth-child(6) {
    flex: 1 0 5%;  
  }

  .headerTbSeven div:nth-child(7),
  .rowTbSeven div:nth-child(7) {
    flex: 1 0 10%;  
  }

  .headerTbSeven div:nth-child(6){
    //padding-left: 12px;
  }

  .headerTbSeven div:nth-child(2){
    //padding-left: 4px;
  }

  .headerTbSeven div:nth-child(3){
    //padding-left: 6px;
  }

  .headerTbSeven div:nth-child(4){
    //padding-left: 8px;
  }

  .headerTbSeven div:nth-child(5){
    //padding-left: 10px;
  }
}