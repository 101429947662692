#config-posconnect-card {
    .card-body {
        flex-direction: column;
        overflow: hidden;
    }

    .card-body .body {
        flex: 1 1 auto;
    }

    label.label-title {
        font: normal normal bold 16px/20px Dosis;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 8px;
    }
}