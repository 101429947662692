.more-options {
  align-self: center;
  i {
    cursor: pointer;
  }
}

.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }
  .disabled {
    color: #807f7c !important;
    &:hover {
      cursor: default !important;
      background-color: unset;
    }
  }
}
