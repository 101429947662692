.note-monitor-card table tbody tr .overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
#monitorCard {
  div.scrollTable {
    display: flex;
    flex-direction: column;
  }
  ul.header {
    flex: 0 0 28px;
  }
  ul.header,
  ul.datarow,
  ul.headerchild,
  ul.datachild {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
  }
  ul.header li:nth-child(3),
  ul.datarow li:nth-child(3),
  ul.header li:nth-child(4),
  ul.datarow li:nth-child(4) {
    flex: 0 0 110px;
  }
  ul.header li,
  ul.datarow li {
    flex: 1 1 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    padding: 5px;
    cursor: pointer;
  }

  ul.datarow li:nth-child(5),
  ul.datarow li:nth-child(7) {
    white-space: nowrap;
  }

  ul.header li {
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    font-weight: bold;
  }

  ul.header li:first-child,
  ul.datarow li:first-child,
  ul.header li:nth-child(2),
  ul.datarow li:nth-child(2) {
    flex: 0 0 40px;
  }
  div.datacontainer:nth-child(2n + 1) {
    background-color: #f8f8f8;
  }
  ul.datarow.norecords:hover li {
    background-color: transparent;
  }
  ul.datarow.norecords li {
    text-align: center;
    flex: 1 1 auto;
  }
  ul.datarow li button,
  ul.datarow li button:hover,
  ul.datarow li button:focus {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }
  ul.datarow li i.material-icons {
    color: #707070;
  }
  ul.datarow li i.material-icons.purple{
    color: #5b2e90;
  }

  ul.headerchild li,
  ul.datachild li {
    flex: 0 0 15%;
  }
  ul.headerchild li {
    border-bottom: 1px solid #e9e9e9;
    font-weight: bold;
    padding: 5px;
  }
  ul.headerchild li:first-child {
    padding: 5px 5px 5px 16px;
  }
  ul.datachild li {
    padding: 5px;
    border-bottom: 1px solid #e9e9e9;
  }
  ul.datachild:last-child li {
    border-bottom: none;
  }
  ul.headerchild li:nth-child(2),
  ul.datachild li:nth-child(2) {
    flex: 0 0 55%;
  }
  ul.headerchild.full li,
  ul.datachild.full li{
    flex: 0 0 100%;
    padding-left: 16px;
  }
  ul.headerchild.full li{
    border-bottom: none;
  }

  ul.datachild div.circle {
    width: 7px;
    height: 7px;
    background-color:#6E35B1;
    display: inline-block;
    border-radius: 3px;
    margin: 0 5px 2px 0;
  }

  div.datacontainer div.child {
    padding-left: 60px;
    background-color: #f8f8f8;
  }
  div.datacontainer div.child.hidden{
    display: none;
  }
  div.datacontainer:nth-child(2n + 1) div.child {
    background-color: #fff;
  }
  div.scroll {
    overflow-x: hidden;
    overflow-y: overlay;
    flex: 1 1 auto;
    margin-top: 5px;
  }
  div.actions {
    border-top: 1px solid #f8f8f8;
    padding: 10px 0px;
    margin-bottom: 3px;
    display: flex;
    min-height: 55px;
  }
  div.actions button {
    margin-right: 5px;
    flex: 0 1 11%;
    position: relative;
  }
  div.actions button.disabled {
    background-color: #f0eefb;
    border-color: #f0eefb;
    color: #b4b1b1;
  }
  div.actions button.disabled:hover,
  div.actions button.disabled:focus {
    box-shadow: none;
    color: #b4b1b1 !important;
  }
  ul.datarow li button:hover i,
  ul.datarow li button:focus i {
    color: #5b2e90;
  }
}
