.custom-form-row {
  display: flex;
  flex-direction: row;

  .custom-form-control {
    margin: 0px 2px 10px;
  }
}

.custom-form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .custom-form-control {
    margin: 0px 2px 10px;
  }
}

.custom-form-control:not(:last-child) {
  margin-right: 15px;
}

.InputAmount {
  padding: 0 10px;
  width: 140px;
  height: 30px;
  border: 1px solid #d3d1d1;
  border-radius: 3px;
  font-size: 20px;
  box-sizing: border-box;
  background: #fff;
}

.ds-pagar {
  padding: 0 5px;
  display: inline-block;
  height: 20px;
  font-size: 20px;
  background: #fff;
}

.ds-total {
  padding: 0 1px;
  width: 135px;
  height: 25px;
  font-size: 20px;
  background: #fff;
}

.custom-form-row-group {
  display: flex;
  flex: 0.64;
  margin-left: 15px;
}

.custom-form-control {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    margin-bottom: 3px;
    white-space: nowrap;
    line-height: 20px;
  }

  input[disabled] {
    background-color: #ebebe4;
    color: #000000;
  }

  select[disabled] {
    background-color: #ebebe4;
    color: #000000;
  }

  textarea {
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    margin: 0em;
    font: 13.3333px Arial;
    padding-top: 5px !important;
  }

  .control {
    padding: 0 10px;
    width: 100%;
    height: 30px;
    border: 1px solid rgb(211, 209, 209);
    border-radius: 3px;
    font-size: 16px;
    box-sizing: border-box;
    background: #fff;
  }

  .icon ~ input,
  img ~ input {
    padding-left: 47px;
  }

  .error {
    color: red;
    position: absolute;
    top: 100%;
  }
}
