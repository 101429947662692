#card-trncentre {

  .options {
    display: flex;
  }

  .input {
    flex: auto !important;
    padding: 10px;
  }

  .quantityChanged {
    background-color: #ffb200 !important;
  }

  .divFooter {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    border: 1px solid #dddbdb;
    border-radius: 5px;
  }

  .title {
    text-align: center;
  }

  .dot {
    flex: none;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    background-color: #ffb200;
    border-radius: 50%;
    display: inline-block;
  }

  .btn {
    width: 200px;
    margin-top: 15px;
    align-self: flex-end;
    margin-right: 10px;
  }

  .card-body div {
    flex: initial;
  }

  .headerTbTrnCentre,
  .rowTbTrnCentre {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .headerTbTrnCentre div,
  .rowTbTrnCentre div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 25px;
  }

  .rowTbTrnCentre div:nth-child(7) {
    flex: 0 0 50%;
    text-align: left;
  }

  .headerTbTrnCentre {
    font-weight: bold;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-top: 10px;
  }

  .rowTbTrnCentre:nth-child(2n) {
    background-color: rgba(0, 0, 0, .05);
  }

  .rowTbTrnCentre:hover {
    background-color: #ffb200;
    box-shadow: 0 0 8px #5b2e90;
    cursor: pointer;
  }

  .rowTbTrnCentre {
    padding-left: 10px;
    padding-bottom: 5px;
  }

  .headerTbTrnCentre div:nth-child(1),
  .rowTbTrnCentre div:nth-child(1) {
    flex: 1 0 14%;
  }

  .info-icon {
    color: #5b2e90;
    font-size: 18px;
    align-items: center;
    vertical-align: middle;

    a {
      vertical-align: super;
    }

    i {
      color: #007BFF;
      margin-right: 6px;
    }

    &:hover {
      cursor: pointer;
    }

  }

  .headerTbTrnCentre div:nth-child(2),
  .rowTbTrnCentre div:nth-child(2) {
    flex: 1 0 27%;
  }

  .headerTbTrnCentre div:nth-child(3),
  .rowTbTrnCentre div:nth-child(3) {
    flex: 1 0 9%;
  }

  .headerTbTrnCentre div:nth-child(4),
  .rowTbTrnCentre div:nth-child(4) {
    flex: 1 0 9%;
  }

  .headerTbTrnCentre div:nth-child(5),
  .rowTbTrnCentre div:nth-child(5) {
    flex: 1 0 9%;
  }

  .headerTbTrnCentre div:nth-child(6),
  .rowTbTrnCentre div:nth-child(6) {
    flex: 1 0 7%;
  }

  .headerTbTrnCentre div:nth-child(7),
  .rowTbTrnCentre div:nth-child(7) {
    flex: 1 0 5%;
  }

  .headerTbTrnCentre div:nth-child(8),
  .rowTbTrnCentre div:nth-child(8) {
    flex: 1 0 8%;
  }

  .headerTbTrnCentre div:nth-child(9),
  .rowTbTrnCentre div:nth-child(9) {
    flex: 1 0 10%;
  }
}