#card-financial-document-receiptment {
  flex: 1 1 auto;
  display: flex;
  margin-right: 0px;
  margin-left: 5px;

  ul.header {
    flex: 0 0 100%;
  }

  ul.header,
  ul.datarow,
  ul.headerchild,
  ul.datachild {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
  }

  div.child {
    padding-left: 65px;
  }

  i.material-icons.purple {
    color: #5b2e90;
  }

  ul.headerchild li,
  ul.datachild li {
    flex: 0 1 auto;
    text-align: left;
    overflow: hidden;
  }

  ul.headerchild li:first-child,
  ul.datachild li:first-child {
    flex: 1 1 60%;
  }

  ul.headerchild li {

    font-weight: bold;
    padding: 5px;
  }

  ul.datachild li {
    padding: 5px;
    border-top: 1px solid #e9e9e9;
  }

  ul.headerchild li:nth-child(2),
  ul.datachild li:nth-child(2) {
    flex: 0 0 30%;
  }

  ul.headerchild li:nth-child(3),
  ul.datachild li:nth-child(3) {
    flex: 0 0 20%;
  }

  ul.datachild div.circle {
    width: 7px;
    height: 7px;
    background-color: #6E35B1;
    display: inline-block;
    border-radius: 3px;
    margin: 0 5px 2px 0;
  }

  .table-responsive {
    margin-top: 18px;
    min-height: 125px;
    flex: 1 1 0;
  }

  .overflow {
    overflow: visible;
  }

  #amountInput,
  #discountInput,
  #interestInput {
    text-align: right;
  }

  .btn-purple {
    width: 130px;
  }

  .btn-group-toggle {
    height: 30px;
    padding-right: 7px;

    .btn {
      line-height: 1.2;
    }

    label {
      box-shadow: unset;
      background-color: white;
      color: #5b2e90;
      border-color: #5b2e90;

      &.focus {
        box-shadow: 0 0 8px #5b2e90;
      }

      &.active {
        background-color: #5b2e90;
        color: white;
      }

      &:first-child {
        width: 38px;
        padding-left: 10px;
      }
    }
  }

  .row-total input {
    &:focus {
      border-color: #ced4da;
      outline: 0;
    }
  }

  #receiptmentClienteContainer {
    display: flex;
    flex: 1;
    position: relative;

    button#btnDeleteCustomer {
      position: absolute;
      top: 0;
      left: 8px;

      background-color: #5b2e90;
      border: none;
      outline: none;

      height: 28px;
      width: 70px;
      margin-top: 29px;

      border-radius: 5px;

      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
    }

    input {
      padding-left: 86px;
    }
  }

  .formControl {
    position: relative;

    .error:nth-child(2) {
      position: absolute;
      top: 45px;
      left: 0;
    }

    .error:nth-child(3) {
      position: absolute;
      top: 68px;
      left: 0;
    }
  }

  button#confirm:disabled:hover {
    cursor: default;
    box-shadow: none;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-top: none;
    border-bottom: none;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: -2px;
      width: calc(100% + 2px);
      height: 2px;
      background-color: #dee2e6;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -2px;
      width: calc(100% + 2px);
      height: 1px;
      background-color: #dee2e6;
    }
  }
}

.card-body {
  #search {
    width: min-content;
    height: min-content;
    border: none;
    align-self: center;
    padding: initial;
    margin-left: 5px;
    margin-top: 12px;
  }

  #search i {
    padding: 3px 5px;
  }

  .ds-total {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .total {
    text-align: end;
    padding-right: 10px;
    font-size: 20px;
    min-width: 140px;
  }

  .row-total {
    justify-content: flex-end;
    padding: 3px 0;
  }

  .checkbox {
    color: #5b2e90;
    vertical-align: middle;
  }

  .processing {
    background-color: #f5eca1 !important;
  }

  .right {
    text-align: right;
    padding-right: 0px;
  }

  span#spanSelectedCustomer {
    margin-bottom: 8px;
  }
}