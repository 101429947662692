.active {
    box-shadow: 0 0 5px 0.05rem #5b2e90;
    border-color: #ced4da;
}
#passContainer
{
    display: flex;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    i { 
        cursor: pointer;
        flex: 0 0 24px;
    }
    i:hover{
        color: #5b2e90;
    }
    input {
        border: none;
        flex: 1 1 auto;
    }
    input:focus{
        border:none;
        box-shadow: none;
        outline: none;
        
    }
   
}