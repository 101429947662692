#sub-footer {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem 1.5rem 1.5rem;

  .retailer-name {
    background: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex: auto;
    min-height: 40px;
  }

  .material-icons {
    color: #ffffff;
    align-items: center;
    font-size: 25px;
  }

  .div-info {
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    background-color: #5b2e90;
    margin-right: 20px;
  }

  .div-retailer-name {
    color: #5b2e90;
    font-weight: bold;
  }
}
