.stockModal {
    padding: 0px !important;
    margin: 0px !important;
    max-height: 90vh;
    height: fit-content;
    width: 80vw !important;
}

.stockModal .modal-header {
    border-bottom: 1px solid #B4B0B0;
}

.stockModal .modal-header .title {
    padding: 25px !important;
    font-size: 22px;
    font-family: Dosis;
    font-weight: 600;
}

.stockModal p.itemName {
    font-size: 18px;
    margin: 0px 20px 20px 0px;
    font-family: Dosis;
    font-weight: 600;
    flex: 1 1 auto;
}

.stockModal span.label {
    color: #6E35B1;
    font-size: 14px;
    font-family: Dosis;
    font-weight: 600;
    flex: 1 1 auto;
    padding: 0px 15px 15px 0px;
}

.stockModal .footer {
    background-color: #EEF0F3;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.stockModal .table-container {
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    padding: 15px;
    padding-top: 0px;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.stockModal .table-container .norecords {
    text-align: center;
    margin-top: 20px;
}

.stockModal table.stock-list tr:hover {
    background-color: #ffb300c5 !important;
}

.stockModal table.stock-list tr td,
.stockModal table.stock-list tr th {
    font-size: 14px;
    font-family: Dosis;
    font-weight: 600;
}

.stockModal table.stock-list tr th img.sort {
    cursor: pointer;
}

.stockModal table.stock-list tr td:first-child,
.stockModal table.stock-list tr th:first-child,
.stockModal table.stock-list tr td:last-child,
.stockModal table.stock-list tr th:last-child {
    width: 130px;
}

.stockModal table.stock-list tr td:last-child,
.stockModal table.stock-list tr th:last-child {
    text-align: center;
}