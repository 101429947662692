.grid-template-row-1 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 3fr;
}

.grid-template-row-2 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
}

.grid-template-row-3 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 2fr;
}

.grid-template-row-4 {
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
}
