.bg-primary {
  background-color: #5b2e90 !important;
}

.btn-secondary {
  background-color: #ff9200;
  color: #ffffff;
  border: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Dosis';
  font-weight: bold;
}

h4 {
  font-size: 15px;
}

input,
select {
  font-family: 'Ubuntu', 'Dosis';
  font-size: 14px;
}

button {
  line-height: normal;
}

span {
  line-height: normal;
}

td {
  border-top: none !important;
}
.table.purple th {
  background-color: #5b2e90;
  color: white;
}

.table-striped.purple tbody tr:nth-of-type(odd) {
  background-color: rgba(91, 46, 144, 0.25);
}

.table-hover.purple tbody tr:hover {
  background-color: rgba(91, 46, 144, 0.45);
  color: #212529;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:focus {
  background-color: rgba(255, 178, 0, 1) !important;
  outline: unset;
  cursor: pointer;
}

.table tbody {
  tr {
    &.selectable {
      cursor: pointer;
      outline: none;
    }
  }

  thead tr th {
    border: unset;
    // position: absolute;
  }

  .thead-linx {
    th {
      background-color: #5b2e90;
      border-color: white;
      color: white;
    }
  }
}

.form-row .form-group {
  align-self: flex-end;
}

.form-row .form-group .btn {
  height: 35px;
  background-color: #5b2e90;
  border-color: #5b2e90;

  &:disabled {
    cursor: unset;
  }

  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: #5b2e90;
      box-shadow: 0 0 8px #5b2e90;
      border-color: #5b2e90;
    }
  }
}

.form-row .form-group .form-control {
  line-height: 1.5px;
  height: calc(1.5em + 0.55rem + 2px);
}

.form-row .form-group label {
  margin-bottom: 2px;
}

.form-row .form-control:focus,  .custom-select:focus{
  box-shadow: 0 0 5px 0.05rem rgba(91, 46, 144, 1);
  border-color: #ced4da;
  outline: none;
}

/* TOOLTIP */
/* Tooltip container */
.custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.custom-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}

/* TABLE MORE OPTIONS */
.more-options {
  font-size: 0px;
  align-self: center;
  i {
    cursor: pointer;
  }
}
.dropdown-content {
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    font-size: 16px;
    color: black;
    padding: 4px 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }
  .disabled {
    color: #807f7c !important;
    &:hover {
      cursor: default !important;
      background-color: unset;
    }
  }
}
/* END OF TABLE MORE OPTIONS */

.has-error {
  border: 1px solid #F35958 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  outline: none;

  &::placeholder {
    color: #F35958;
  }
}
