#config-farmacia-popular-card{    
    label.label-title {
        font: normal normal bold 16px/20px Dosis;
        text-align: center;    
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 8px;
    }

    div.body{
        flex: 1 1 auto;
    }
}

#cadastrar-computador-link {
    color: #4482FF;
    text-decoration: underline;
    margin-left: 12px;
    position: relative;

    align-items: center;
    justify-content: center;
    float: right;
}

#cadastrar-computador-link::after {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    white-space: pre-wrap;
    pointer-events: none;
    transition: 500ms;
    opacity: 0;
    content: attr(aria-label);
    background: #fff;
    color: #222;
    border-radius: 5px;
    padding: 6px;
    position: absolute;
    width: 300px;
    text-align: center;
    height: fit-content;

    line-height: 1.5rem;
    z-index: 999;
    
    left: -312px;
}

#cadastrar-computador-link:hover {
    cursor: pointer;
}

#cadastrar-computador-link:hover::after {
    opacity: 1;
    pointer-events: all;
}

.input-with-copy-container {
    position: relative;

    input {
        padding-right: 50px;
    }

    i {
        position: absolute;
        right: 10px;
        top: 5px;
    }       
}
