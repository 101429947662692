#increaseDecreaseInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    height: 32px;
    border-radius: 16px;
    overflow: hidden;

    input {
        border: 1px solid #ccc;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
    }

    .increment-button,
    .decrement-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 25px;
        width: 30px;
        height: 30px;
        background-color: white;
        cursor: pointer;
        border: 1px solid #C0C0C0;
        box-shadow: 1px 2px 0px lightgray;
        border-radius: 50%;
    }

    .increment-button:hover,
    .decrement-button:hover {
        background-color: #ab86d6;
    }

    .increment-button:focus,
    .decrement-button:focus {
        outline: none;
    }

    .div-text {
        position: absolute;
        margin-top: -3px;
    }
}