#linx-conecta-init-component {
  .conecta-form-header {
    margin: 0;
    padding: 0;

    .form-group label.conecta-label {
      margin-bottom: 6px;
    }

    #cardQuery {
      font-family: 'Dosis';

      &::placeholder {
        font-size: 16px;
      }
    }

    #empresaId {
      font-family: 'Dosis';
    }
  }


  .title {
    text-align: center;
  }

  .statusGreen {
    color: green;

  }

  .statusBlue {
    color: blue;

  }

  .statusRed {
    color: red;

  }

  .headerTbConecta,
  .rowTbConecta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .headerTbConecta div,
  .rowTbConecta div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 25px;
    padding: 5px;
  }

  .headerPecPbm,
  .rowPecPbm {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
  }

  .headerPecPbm div,
  .rowPecPbm div {
    padding: 5px;
  }

  .rowTbConecta div:nth-child(7) {
    flex: 0 0 50%;
    text-align: left;
  }

  .headerTbConecta {
    font-weight: bold;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-top: 10px;
  }

  .rowTbConecta:nth-child(2n) {
    background-color: rgba(0, 0, 0, .05);
  }

  .rowTbConecta:hover {
    background-color: #ffb200;
    box-shadow: 0 0 8px #5b2e90;
    cursor: pointer;
  }

  .rowTbConecta {
    padding-left: 10px;
    padding-bottom: 5px;
  }

  .headerTbConecta div:nth-child(1),
  .rowTbConecta div:nth-child(1) {
    flex: 1 0 25%;
  }

  .headerTbConecta div:nth-child(2),
  .rowTbConecta div:nth-child(2) {
    flex: 1 0 15%;
  }

  .headerTbConecta div:nth-child(3),
  .rowTbConecta div:nth-child(3) {
    flex: 1 0 25%;
  }

  .headerTbConecta div:nth-child(4),
  .rowTbConecta div:nth-child(4) {
    flex: 1 0 15%;
  }

  .headerTbConecta div:nth-child(5),
  .rowTbConecta div:nth-child(5) {
    flex: 1 0 10%;
  }

  .headerTbConecta div:nth-child(6),
  .rowTbConecta div:nth-child(6) {
    flex: 1 0 10%;
  }

  .btn-cancel {
    margin-top: 20px !important;
  }

  .btnTbConecta {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .btnTbConecta .btn-rows {
    flex: 1 1 auto;
    min-width: auto;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .btn-tops {
    padding: 10px !important;
    margin-top: 48px !important;
    height: 34px !important;
  }

  .btn-rows {
    height: 24px !important;
    border: 1px solid #5b2e90 !important
  }

  .headerTbConecta div:nth-child(6) {
    padding-left: 12px;
  }

  .headerTbConecta div:nth-child(2) {
    padding-left: 4px;
  }

  .headerTbConecta div:nth-child(3) {
    padding-left: 6px;
  }

  .headerTbConecta div:nth-child(4) {
    padding-left: 8px;
  }

  .headerTbConecta div:nth-child(5) {
    padding-left: 10px;
  }

  .full-screen-modal {
    width: 100vw;
    /* 100% da largura da viewport */
    height: 100vh;
    /* 100% da altura da viewport */
    margin: 0;
    /* Remove margem padrão */
    padding: 0;
    /* Remove padding padrão */
    display: flex;
    flex-direction: column;
  }

  .full-screen-modal-body {
    flex-grow: 1;
    padding: 0;
    /* Remove padding do corpo do modal */
    overflow: hidden;
    /* Garante que o conteúdo não saia da área visível */
  }

  .full-screen-modal-footer {
    padding: 10px;
    text-align: center;
  }
}