.page {
  width: 90vw;
  margin: 1.5rem;
}
.box-left {
  // justify-content: center;
}
.container-fuid {
  overflow: hidden;
  position: fixed;
  z-index: 1;
  width: 100%;
  min-width: 980px;
  height: 100vh;
}
.title {
  text-align: center;
}
.page-login {
  background-color: #fff;
}

.page-login button {
  height: 40px;
}

.page-login .left img {
  margin: 0 auto;
  padding-left: 7.5px;
}

.page-login .right .flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-login picture {
  margin-bottom: 50px;
}

.page-login .custom-control-position {
  top: 5px;
}

.page-login .copyright {
  color: #fff;
  font-size: 1rem;
  position: absolute;
  bottom: 0px;
  left: 30px;
}

.page-login .link-support {
  position: absolute;
  right: 20px;
  bottom: 0;
}
.page-login picture {
  display: block;
}
.colum-height {
  height: 100vh;
}

.text-brand-linx {
  color: #ffb200;
  font-size: 2.625rem;
  font-weight: 300;

  margin-bottom: 0;
}

.text-brand-product {
  color: #fff;
  font-size: 3rem;
  font-weight: 300;
}

.page-login picture,
.form-login {
  width: 100%;
  max-width: 340px;
}

.form-login .control-label {
  font-size: 0.875rem;
}

.form-recover-password h1 {
  font-size: 1.875rem;
} /* 30px */
.center {
  justify-content: center;
}

@media (max-width: 414px) {
  .page-login picture,
  .form-login {
    width: 90%;
  }

  .page-login .link-support {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-login picture,
  .form-login {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .page-login .copyright {
    display: none;
  }

  .colum-height-a {
    height: 10vh;
  }

  .colum-height-b {
    height: 90vh;
  }

  .form-login .control-label {
    font-size: 0.8rem;
  }

  .text-brand-linx {
    font-size: 1.25rem;
  }

  .text-brand-product {
    font-size: 1rem;
  }
}
