.toast-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: min-content;
  z-index: 10000;
  right: 20px;
  top: 3.5rem;

  .toast {
    width: 330px;
    align-self: flex-end;
    flex-basis: auto;
  }

  .toast-header {
    border-bottom: unset;

    .header-icon {
      padding-right: 10px;
      font-size: 22px;
    }

    .header-title {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .success .toast-header {
    opacity: 0.7;

    .header-icon {
      width: 35px;
    }
  }

  .success .toast-body {
    opacity: 0.6;
  }

  .success .toast-header,
  .success .toast-body {
    color: white;
    background-color: green;
  }

  .error .toast-header {
    opacity: 0.9;
  }

  .error .toast-body {
    opacity: 0.7;
  }

  .error .toast-header,
  .error .toast-body {
    color: white;
    background-color: #e52a24;
  }

  .warning .toast-header {
    opacity: 0.7;
  }

  .warning .toast-body {
    opacity: 0.6;
  }

  .warning .toast-header,
  .warning .toast-body {
    color: white;
    background-color: #ff9701;
  }

  .info .toast-header {
    opacity: 0.9;
  }

  .info .toast-body {
    opacity: 0.7;
  }

  .info .toast-header,
  .info .toast-body {
    color: white;
    background-color: #9c28b1;
  }
}
